import React from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { markenStandortThemeBodySelector } from '../../../modules/selectors/standort/theme';

const LegacyHome = loadable(() => import('../../components/themes/legacy/home/LegacyHome'));
const DefaultHome = loadable(() => import('./DefaultHome'));

function HomeSelector() {
    const markenStandortThemeBody = useSelector(markenStandortThemeBodySelector);

    switch (markenStandortThemeBody.home.componentName) {
        case 'LegacyHome':
            return <LegacyHome />;
        case 'DefaultHome':
            return <DefaultHome />;
        default:
            throw new Error(`Unexpected markenStandortThemeBody, got: ${markenStandortThemeBody.home.componentName}`);
    }
}

export default HomeSelector;
